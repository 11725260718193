import '@nlux/themes/nova.css';
import '../../styles/io-chat-custom.css';
import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import { AiChat } from '@nlux/react';
import { ReactReduxContext } from 'react-redux';

import { useChatAdapter } from './useChatAdapter';

interface IProps {
  baseUrl: string;
  apiKey: string;
  userData: any;
  token: string;
  setLayersActive: (any) => void;
  setLocationHighlight: (any) => void;
  setMapBounds: (any) => void;
}

const IOChat = (props: IProps) => {
  const { baseUrl, apiKey, userData, token } = props;
  const { store } = useContext(ReactReduxContext);

  const authHeaders = useMemo(
    () => ({
      'x-api-key': apiKey,
    }),
    [apiKey]
  );

  const [conversationHistory, setConversationHistory] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [threadId, setThreadId] = useState('');

  // Get data from sessionStorage on component mount
  // useEffect(() => {
  //   let storedProject = sessionStorage.getItem('project');
  //   if (storedProject) {
  //     setProject(storedProject);
  //   }
  // }, []); // Empty dependency array ensures it runs only once on mount

  // Set data to sessionStorage whenever 'data' changes
  // useEffect(() => {
  //   sessionStorage.setItem('project', project);
  // }, [project]);

  useEffect(() => {
    async function getChatHistory() {
      const storedThreadId = sessionStorage.getItem('thread_id');
      if (storedThreadId) {
        setThreadId(storedThreadId);
      } else {
        fetch(`${baseUrl}/threads`, {
          method: 'POST',
          headers: authHeaders,
          body: JSON.stringify({ metadata: { user_id: userData.nickname } }),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            const newThreadId = data['thread_id'];
            sessionStorage.setItem('thread_id', newThreadId);
            setThreadId(newThreadId);
          });
      }
      if (threadId) {
        const response = await fetch(`${baseUrl}/threads/${threadId}/history`, {
          method: 'GET',
          headers: authHeaders,
        });
        return await response.json();
      }
    }
    getChatHistory().then((data) => {
      if (data) {
        if (Array.isArray(data) && data.length) {
          const conversationHistory = data[0].values.messages.reduce(function (filtered, entry) {
            if (['human', 'ai'].includes(entry.type) && entry.content) {
              filtered.push({
                message: entry.content,
                role: entry.type === 'human' ? 'user' : 'assistant',
              });
            }
            return filtered;
          }, []);
          setConversationHistory(conversationHistory);
        } else {
          console.log('getChatHistory data', data);
        }
      }
      setLoaded(true);
    });
  }, [threadId]);

  const inputPreProcessor = useCallback(
    (input, conversationHistory) => {
      // rename conversationHistory to how langserve expects chat_history
      return {
        assistant_id: 'fe096781-5601-53d2-b2f6-0d3403f7e9ca',
        input: {
          messages: [
            {
              role: 'human',
              content: input,
            },
          ],
        },
        config: {
          configurable: {
            token: token,
            projects: store.getState().global.projects,
          },
        },
      };
    },
    [token, store.getState().global.projects]
  );

  const outputPreProcessor = useCallback((output) => {
    return output.at(-1).content;
  }, []);

  const adapter = useChatAdapter({
    url: `${baseUrl}/threads/${sessionStorage.getItem('thread_id')}/runs`,
    headers: authHeaders,
    inputPreProcessor: inputPreProcessor,
    outputPreProcessor: outputPreProcessor,
    useInputSchema: false,
    dataTransferMode: 'batch',
  });

  const messageReceivedCallback = useCallback((message) => {
    console.log(message);
  }, []);

  if (!isLoaded) {
    return <div>Loading</div>;
  }

  const conversationStarters = {
    io: [
      {
        label: 'Show my project area',
        prompt: 'Show my "Kenya Counties" AOI.',
      },
      {
        label: 'What data is available over my project area?',
        prompt:
          'Show me "Kenya Crops 2023" layer over my "Kenya Counties" AOI and give me a list of datasets in my projects for there.',
      },
      {
        label: 'Report key metrics for project area.',
        prompt: 'Show the metrics for my "Kenya Counties" AOI.',
      },
    ],
  };

  return (
    <AiChat
      adapter={adapter}
      className="ioChat"
      events={{
        messageReceived: messageReceivedCallback,
      }}
      personaOptions={{
        assistant: {
          name: '',
          avatar: '/static/bob_avatar.png',
          tagline: `
                    Hi! I'm Bob, your new data librarian and image analyst.  
                    I'm here to support you find and access relevant satellite  
                    imagery, geospatial data, and analytics layers for your  
                    areas of interest. I can assist with orchestrating new  
                    analytics production and satellite tasking. I'll also  
                    help investigate what's driving observed changes using  
                    metrics, prior reporting, and the latest news.
          `,
          // 'Your AI Geospatial Sidekick',
        },
        user: {
          name: userData.name || 'User',
        },
      }}
      conversationOptions={{
        conversationStarters: conversationStarters[userData.share] || [
          {
            label: 'Show my project area',
            prompt: "Show my shared project's default AOI.",
          },
          {
            label: 'What data is available over my project area?',
            prompt:
              "Show my shared project's default data layer over my shared project's default AOI and give me a list of datasets in my projects for there.",
          },
          {
            label: 'Report key metrics for project area.',
            prompt: "Show the metrics for my shared project's default AOI.",
          },
        ],
        // autoScroll: true,
        layout: 'bubbles',
      }}
      messageOptions={{
        markdownLinkTarget: 'self',
      }}
      initialConversation={conversationHistory.length > 0 ? conversationHistory : null}
      promptBoxOptions={{
        autoFocus: true,
        placeholder: 'Type your message here',
      }}
      displayOptions={{
        height: '100%',
        width: '100%',
      }}
    />
  );
};
export default IOChat;
