import * as React from 'react';
import { PureComponent } from 'react';
import area from '@turf/area';

interface ControlPanelProps {
  containerComponent: any;
  polygon: any;
}

export default class ControlPanel extends PureComponent<ControlPanelProps> {
  render() {
    const polygon = this.props.polygon;
    const polygonArea = polygon && area(polygon) / 1e6;
    return (
      <div className="control-panel">
        <p>DRAW POLYGON</p>
        {polygon && (
          <div>
            <p>{polygonArea.toFixed(2)} km²</p>
            <button
              onClick={() => {
                console.log(polygon);
                navigator.clipboard.writeText(JSON.stringify(polygon));
              }}
            >
              GeoJSON
            </button>
          </div>
        )}
      </div>
    );
  }
}
