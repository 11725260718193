import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import classnames from 'classnames';
import { capitalize, identity, omit, pickBy } from 'lodash';
import IconLeft from 'mdi-material-ui/ArrowLeft';
import IconClose from 'mdi-material-ui/Close';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Link from 'redux-first-router-link';

import {
  InlineEditCard,
  Input,
  MuiSelect,
  setupErrors,
  Spinner,
  UserMenu,
  validEmailRule,
  valueChangedRule,
} from '@marapp/earth-shared';

import { MAP_EXTERNAL_IDP_URL, PUBLIC_URL } from '../../config';
import ProfileService from '../../services/ProfileService';
import { APP_LOGO } from '../../theme';
import { Auth0Context } from '../../utils/contexts';
import { SessionStorage } from '@marapp/earth-shared';

interface IProps {
  page: string;
  baseUrl: string;
  resetStore?: () => void;
}

interface IMapViewRedirectProps {
  children: React.ReactNode;
  hasLeftOrg: boolean;
  resetStore?: () => void;
}

enum RESET_PASSWORD_STATE {
  INITIAL,
  SENDING,
  SENT,
  NOTIFICATION_DISMISS,
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
  },
  alert: {
    alignItems: 'center',
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
  },
  logo: {
    padding: theme.spacing(0.5, 1, 0.5, 0),
    marginLeft: theme.spacing(2),
    height: theme.spacing(7),
    width: 'auto',
  },
  redirectIcon: {
    color: theme.palette.text.secondary,
  },
  mapRedirect: {
    border: 'none !important',
    display: 'flex',
    alignItems: 'center',
  },
  deleteCheckboxContainer: {
    alignItems: 'flex-start',
  },
  deleteCheckboxLabel: {
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export function ProfileComponent(props: IProps) {
  const { page, resetStore } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    getValues,
    register,
    formState,
    errors: formErrors,
    control,
  } = useForm({
    mode: 'all',
  });

  const { userData, getAccessToken, logout, login, isAuthenticated, updateToken } =
    useContext(Auth0Context);
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isUserRolesLoading, setIsUserRolesLoading] = useState(true);
  const [userName, setUserName] = useState('');
  const [pendingEmail, setPendingEmail] = useState(null);
  const [serverErrors, setServerErrors] = useState();
  const [userProfile, setUserProfile] = useState({
    firstName: '',
    lastName: '',
    name: '',
    country: '',
    institution: '',
    groups: [],
  });
  const [countries, setCountries] = useState([]);
  const [userCountry, setUserCountry] = useState({ label: '' });
  const [workspace, setWorkspace] = useState('io');
  const [resetPasswordState, setResetPasswordState] = useState(RESET_PASSWORD_STATE.INITIAL);
  const [markedOrgsForLeave, setMarkedOrgsForLeave] = useState({});
  const [hasLeftOrg, setHasLeftOrg] = useState(false); // detect when the user is leaving an org in order to
  // change the map link to a "native" one (classic navigation to reset the whole state)
  const [userRoles, setUserRoles] = useState({});
  const [workspaces, setWorkspaces] = useState(false);
  const [confirmDeleteAccount, setConfirmDeleteAccount] = useState(false);

  const { touched, isValid } = formState;
  const renderErrorFor = setupErrors(formErrors, touched);

  const processUserName = ({ firstName, lastName, name }) => {
    setUserName(firstName && lastName ? `${firstName} ${lastName}` : name);
  };

  const groupRolesByOrganization = (groups) => {
    const result = groups.reduce((acc, c) => {
      const groupTokens = c.name.split('-');

      const groupRole = capitalize(groupTokens.pop());
      const groupName = groupTokens.join('-');

      acc[groupName] = acc[groupName] || [];
      acc[groupName].push(groupRole);

      return acc;
    }, {});

    setUserRoles(result);
  };

  const fetchUserProfile = useCallback(async () => {
    try {
      const response = await ProfileService.fetchProfile();
      setUserProfile(response.data);
      processUserName(response.data);

      response.data?.pendingEmail && setPendingEmail(response.data.pendingEmail);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchUserGroups = useCallback(async () => {
    try {
      const response = await ProfileService.fetchProfile({
        include: 'groups,primaryGroups',
      });
      groupRolesByOrganization(response.data?.groups);

      const workspaceIdToDescription = response.data.primaryGroups.reduce((acc, c) => {
        acc[c.name] = c.description;
        return acc;
      }, {});
      setWorkspaces(workspaceIdToDescription);
    } finally {
      setIsUserRolesLoading(false);
    }
  }, []);

  //const fetchProfileCountries = useCallback(async () => {
  //  try {
  //    const response = await ProfileService.fetchProfileCountries();
  //
  //    setCountries(response.data);
  //  } catch (error) {
  //    setCountries([]);
  //  }
  //}, []);

  useEffect(() => {
    async function fetchToken() {
      const accessToken = await getAccessToken();
      setToken(accessToken);
    }
    if (isAuthenticated) {
      fetchToken();
    }
  }, [isAuthenticated, getAccessToken]);

  useEffect(() => {
    fetchUserProfile();
    fetchUserGroups();
    //fetchProfileCountries();
  }, []);

  useEffect(() => {
    if (userProfile && countries) {
      const country = countries.find((country) => country.value === userProfile.country);

      setUserCountry(country);
    }
  }, [userProfile, countries]);

  useEffect(() => {
    if (userData) {
      const workspace = userData.email.split('@')[0];

      setWorkspace(workspace);
    }
  }, [userData]);

  async function onUpdateProfile(e?, setIsEditing?, setIsLoading?, setServerErrors?) {
    e.preventDefault();

    const values = getValues();

    try {
      setIsLoading && setIsLoading(true);
      const { country, ...rest } = values;

      const parsed = {
        ...rest,
        ...(country && { country: country.value }),
      };

      const response = await ProfileService.updateProfile(parsed);
      setUserProfile(response.data);
      processUserName(response.data);

      setIsEditing && setIsEditing(false);
      setIsLoading && setIsLoading(false);
    } catch (error) {
      setIsLoading && setIsLoading(false);
      setServerErrors && setServerErrors(error.data?.errors);
    }
  }

  async function sendResetEmail(e) {
    e.preventDefault();

    setResetPasswordState(RESET_PASSWORD_STATE.SENDING);

    await ProfileService.resetPassword();

    setResetPasswordState(RESET_PASSWORD_STATE.SENT);
  }

  function switchMarkOrgForLeave(e, org) {
    e.preventDefault();

    setMarkedOrgsForLeave(
      pickBy({ ...markedOrgsForLeave, [org]: !markedOrgsForLeave[org] }, identity)
    );
  }

  async function onSubmitOrgLeave(e?, setIsEditing?, setIsLoading?, setServerErrors?) {
    e.preventDefault();

    const orgsToLeave = Object.keys(markedOrgsForLeave);

    setIsLoading && setIsLoading(true);

    try {
      await ProfileService.leaveOrganizations(orgsToLeave);
      await updateToken();

      setUserRoles({ ...omit(userRoles, orgsToLeave) });
      setHasLeftOrg(true);
      setMarkedOrgsForLeave({});

      setIsEditing && setIsEditing(false);
      setIsLoading && setIsLoading(false);
    } catch (error) {
      setIsLoading && setIsLoading(false);
      setServerErrors && setServerErrors(error.data?.errors);
    }
  }

  async function onEmailChange(e?, setIsEditing?, setIsLoading?, setServerErrors?) {
    e.preventDefault();
    const formData = getValues();

    try {
      setIsLoading && setIsLoading(true);
      const response = await ProfileService.changeEmail(formData);
      setPendingEmail(response.data?.pendingEmail);
      setIsEditing && setIsEditing(false);
      setIsLoading && setIsLoading(false);
    } catch (error) {
      setIsLoading && setIsLoading(false);
      setServerErrors && setServerErrors(error.data?.errors);
    }
  }

  async function onCancelEmailChange(e) {
    e.preventDefault();

    try {
      const response = await ProfileService.cancelEmailChange();
      setUserProfile(response.data);
      setPendingEmail(null);
    } catch (error) {
      setServerErrors && setServerErrors(error.data?.errors);
    }
  }

  async function deleteAccount(e?, setIsEditing?, setIsLoading?, setServerErrors?) {
    e.preventDefault();

    setIsLoading && setIsLoading(true);

    try {
      await ProfileService.deleteAccount();

      setIsEditing && setIsEditing(false);
      setIsLoading && setIsLoading(false);

      await logout();
    } catch (error) {
      setIsLoading && setIsLoading(false);
      setServerErrors && setServerErrors(error.data?.errors);
    }
  }

  return isLoading ? (
    <Spinner size="large" />
  ) : (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Box>
          <MapViewRedirect hasLeftOrg={hasLeftOrg} resetStore={resetStore}>
            <img src={APP_LOGO.default} className={`${classes.logo} marapp-qa-logo`} alt="" />
            <div>
              <Button startIcon={<IconLeft className={classes.redirectIcon} />}>
                {t('Return to Sidekick')}
              </Button>
            </div>
          </MapViewRedirect>
        </Box>

        <UserMenu
          selected={page}
          userData={userData}
          isAuthenticated={isAuthenticated}
          profileLinkProps={{
            component: Link,
            to: { type: 'PROFILE' },
          }}
          onLogin={login}
          onLogout={logout}
          onSignUp={() => login({ initialScreen: 'signUp' })}
          onSubmitFeedback={async (values) => {
            return ProfileService.sendFeedback(values);
          }}
        />
      </AppBar>

      <Container maxWidth="md" className={`${classes.root} marapp-qa-user-profile`}>
        <Grid container={true} spacing={1} id="portal">
          <Grid item={true} xs={12}>
            <Typography align="center" component="h1" variant="h5" paragraph={true}>
              {t('Manage your account').toUpperCase()}
            </Typography>
          </Grid>

          {resetPasswordState === RESET_PASSWORD_STATE.SENT && (
            <Grid item={true} xs={12} className="marapp-qa-resetpassword-alert">
              <Alert
                action={
                  <IconButton
                    className="marapp-qa-resetpassword-dismiss"
                    onClick={() => setResetPasswordState(RESET_PASSWORD_STATE.NOTIFICATION_DISMISS)}
                  >
                    <IconClose />
                  </IconButton>
                }
                classes={{ root: classes.alert }}
                severity="success"
                variant="filled"
              >
                {t('An email has been sent to {{email}} with a link to reset your password', {
                  email: userData.email,
                })}
              </Alert>
            </Grid>
          )}

          <Grid item={true} xs={12}>
            <InlineEditCard
              {...(!MAP_EXTERNAL_IDP_URL && {
                render: () => (
                  <>
                    <Box mb={2}>
                      <Input
                        className="marapp-qa-inputfirstname"
                        name="firstName"
                        placeholder={t('First Name')}
                        label={t('First Name')}
                        defaultValue={userProfile.firstName}
                        error={renderErrorFor('firstName')}
                        required={true}
                        inputRef={register({
                          maxLength: {
                            value: 40,
                            message: t('max length is', { field: t('First Name'), value: 40 }),
                          },
                          minLength: {
                            value: 1,
                            message: t('min length is', { field: t('First Name'), value: 1 }),
                          },
                          required: {
                            value: true,
                            message: t('required', { field: t('First Name') }),
                          },
                        })}
                      />
                    </Box>
                    <Box mb={2}>
                      <Input
                        className="marapp-qa-inputlastname"
                        name="lastName"
                        placeholder={t('Last Name')}
                        label={t('Last Name')}
                        defaultValue={userProfile.lastName}
                        error={renderErrorFor('lastName')}
                        required={true}
                        inputRef={register({
                          maxLength: {
                            value: 80,
                            message: t('max length is', { field: t('Last Name'), value: 80 }),
                          },
                          minLength: {
                            value: 1,
                            message: t('min length is', { field: t('Last Name'), value: 1 }),
                          },
                          required: {
                            value: true,
                            message: t('required', { field: t('Last Name') }),
                          },
                        })}
                      />
                    </Box>
                  </>
                ),
                validForm: isValid && formState.isDirty,
                onSubmit: onUpdateProfile,
              })}
            >
              <Typography color="textSecondary" variant="subtitle2" gutterBottom={true}>
                {t('Name')}
              </Typography>
              <Typography className="marapp-qa-user-name">{userName}</Typography>
            </InlineEditCard>
          </Grid>

          <Grid item={true} xs={12}>
            <InlineEditCard
              {...(!MAP_EXTERNAL_IDP_URL && {
                render: () => (
                  <>
                    <Box mb={2}>
                      <Input
                        className="marapp-qa-inputemail"
                        name="email"
                        placeholder={t('Email')}
                        label={t('Email')}
                        defaultValue={userData.email}
                        error={renderErrorFor('email')}
                        required={true}
                        inputRef={register({
                          required: t('Please enter a valid email') as string,
                          validate: {
                            valueChangedRule: (value) => valueChangedRule(value, userData.email),
                            validEmailRule: validEmailRule(),
                          },
                        })}
                      />
                    </Box>

                    <Box mb={2}>
                      <Typography variant="body2">
                        {t(
                          'After saving, we will send an email to your new email address to confirm the change'
                        )}
                        .
                        <br />
                        {t(
                          'Be sure to check your spam folder if you do not receive the email in a few minutes'
                        )}
                        .
                      </Typography>
                    </Box>
                  </>
                ),
                onSubmit: onEmailChange,
                validForm: isValid,
              })}
            >
              {/* @ts-ignore */}
              {pendingEmail ? (
                <>
                  <Typography color="textSecondary" variant="subtitle2" gutterBottom={true}>
                    {t('Current email')}
                  </Typography>

                  <Typography className="marapp-qa-user-email">{userData.email}</Typography>

                  <Box mt={2}>
                    <Box display="flex" alignItems="center">
                      <Typography color="textSecondary" variant="subtitle2" gutterBottom={true}>
                        {`${t('New Email')} (${t('Pending Confirmation')})`}
                      </Typography>

                      <Box ml={1}>
                        <Button
                          onClick={onCancelEmailChange}
                          type="button"
                          className="marapp-qa-actioncancelupdate"
                          size="small"
                          variant="outlined"
                        >
                          {t('cancel update')}
                        </Button>
                      </Box>
                    </Box>

                    <Typography className="marapp-qa-user-pending-email">{pendingEmail}</Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Typography color="textSecondary" variant="subtitle2" gutterBottom={true}>
                    {t('Email')}
                  </Typography>

                  <Typography className="marapp-qa-user-email">{userData.email}</Typography>
                </>
              )}
            </InlineEditCard>
          </Grid>

          <Grid item={true} xs={12}>
            <InlineEditCard>
              <Typography color="textSecondary" variant="subtitle2" gutterBottom={true}>
                {'Reset chat history'}
              </Typography>

              <Box mt={1}>
                <Button
                  className="marapp-qa-resetpassword"
                  size="large"
                  variant="outlined"
                  disableRipple={true}
                  onClick={async (event) => {
                    event.stopPropagation();
                    SessionStorage.remove('thread_id');
                  }}
                >
                  Reset History
                </Button>
              </Box>
            </InlineEditCard>
          </Grid>

          <Grid item={true} xs={12}>
            <InlineEditCard>
              <Typography color="textSecondary" variant="subtitle2" gutterBottom={true}>
                {t('Password reset')}
              </Typography>

              <Typography>
                {t(`We'll send you an email to reset your password`)}.
                <br />
                {t(
                  `Be sure to check your spam folder if you do not receive the email in a few minutes`
                )}
                .
              </Typography>

              <Box mt={1}>
                <Button
                  className="marapp-qa-resetpassword"
                  size="large"
                  variant="outlined"
                  disabled={
                    !!MAP_EXTERNAL_IDP_URL || resetPasswordState !== RESET_PASSWORD_STATE.INITIAL
                  }
                  onClick={sendResetEmail}
                  endIcon={
                    resetPasswordState === RESET_PASSWORD_STATE.SENDING && (
                      <CircularProgress size={16} />
                    )
                  }
                >
                  {resetPasswordState === RESET_PASSWORD_STATE.INITIAL
                    ? t('Send reset email')
                    : resetPasswordState === RESET_PASSWORD_STATE.SENDING
                    ? t('Sending reset email')
                    : t('Email sent')}
                </Button>
              </Box>
            </InlineEditCard>
          </Grid>

          <Grid item={true} xs={12}>
            <InlineEditCard
              validForm={isValid && formState.isDirty}
              onSubmit={onUpdateProfile}
              render={() => (
                <Grid container={true}>
                  <Grid item={true} xs={12} md={7}>
                    <Box mb={1}>
                      <Controller
                        as={MuiSelect}
                        control={control}
                        className="marapp-qa-profile-country"
                        name="country"
                        options={countries}
                        error={renderErrorFor('country')}
                        defaultValue={userCountry}
                        placeholder={t('Select country or region')}
                        label={t('Country or region')}
                        rules={{
                          required: {
                            value: true,
                            message: t('required', { field: t('Country or region') }),
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
            >
              <Typography color="textSecondary" variant="subtitle2" gutterBottom={true}>
                {t('Country or region')}
              </Typography>

              <Typography
                className="marapp-qa-user-country"
                color={userCountry?.label ? 'textPrimary' : 'textSecondary'}
              >
                {userCountry?.label || t('No country provided')}
              </Typography>
            </InlineEditCard>
          </Grid>

          <Grid item={true} xs={12}>
            <InlineEditCard
              render={() => (
                <>
                  <Box mb={2}>
                    <Input
                      className="marapp-qa-input-institution"
                      name="institution"
                      placeholder={t('Institution')}
                      label={t('Institution')}
                      defaultValue={userProfile?.institution}
                      error={renderErrorFor('institution')}
                      inputRef={register({
                        maxLength: {
                          value: 127,
                          message: t('max length is', { field: t('Institution'), value: 127 }),
                        },
                        minLength: {
                          value: 1,
                          message: t('min length is', { field: t('Institution'), value: 1 }),
                        },
                      })}
                    />
                  </Box>
                </>
              )}
              validForm={isValid && formState.isDirty}
              onSubmit={onUpdateProfile}
            >
              <>
                <Typography color="textSecondary" variant="subtitle2" gutterBottom={true}>
                  {t('Institution')}
                </Typography>

                <Typography
                  className="marapp-qa-institution"
                  color={userProfile?.institution ? 'textPrimary' : 'textSecondary'}
                >
                  {userProfile?.institution || t('No institution provided')}
                </Typography>
              </>
            </InlineEditCard>
          </Grid>

          <Grid item={true} xs={12}>
            <InlineEditCard
              render={() => (
                <>
                  <Box mb={1} display="flex" justifyContent="space-between">
                    <Typography color="textSecondary" variant="subtitle2" gutterBottom={true}>
                      {t('Workspaces')}
                    </Typography>

                    {isUserRolesLoading && <CircularProgress size={20} />}
                  </Box>

                  <Box mb={1}>
                    <Grid container={true} spacing={1}>
                      <Grid item={true} xs={6}>
                        <Typography variant="subtitle1">{t('Workspace name')}</Typography>
                      </Grid>

                      <Grid item={true} xs={3}>
                        <Typography variant="subtitle1">{t('Role')}</Typography>
                      </Grid>

                      {Object.keys(userRoles).map((org, index) => (
                        <Fragment key={org}>
                          <Grid item={true} xs={6}>
                            <Typography
                              className={`marapp-qa-workspace marapp-qa-workspace-${index}`}
                            >
                              {workspaces[org]}
                            </Typography>
                          </Grid>

                          <Grid item={true} xs={true}>
                            <Typography
                              className={`marapp-qa-workspace-role marapp-qa-workspace-role-${index}`}
                              color={markedOrgsForLeave[org] ? 'textSecondary' : 'textPrimary'}
                            >
                              {markedOrgsForLeave[org]
                                ? t('marked for removal')
                                : userRoles[org].join(', ')}
                            </Typography>
                          </Grid>

                          <Grid item={true}>
                            <Button
                              className={classnames(
                                markedOrgsForLeave[org]
                                  ? 'marapp-qa-cancelorgaction'
                                  : 'marapp-qa-leaveorg'
                              )}
                              onClick={(e) => switchMarkOrgForLeave(e, org)}
                              size="small"
                              variant="outlined"
                            >
                              {t(markedOrgsForLeave[org] ? 'Cancel' : 'leave workspace')}
                            </Button>
                          </Grid>
                        </Fragment>
                      ))}
                    </Grid>
                  </Box>
                </>
              )}
              validForm={Object.keys(markedOrgsForLeave).length > 0}
              onSubmit={onSubmitOrgLeave}
              onCancel={() => setMarkedOrgsForLeave({})}
              hideEditButton={isUserRolesLoading || !Object.keys(userRoles).length}
            >
              <>
                <Box mb={1} display="flex" justifyContent="space-between">
                  <Typography color="textSecondary" variant="subtitle2" gutterBottom={true}>
                    {t('Workspaces')}
                  </Typography>

                  {isUserRolesLoading && <CircularProgress size={20} />}
                </Box>

                {isUserRolesLoading ? null : Object.keys(userRoles).length ? (
                  <Grid container={true} spacing={1}>
                    <Grid item={true} xs={6}>
                      <Typography variant="subtitle1">{t('Workspace name')}</Typography>
                    </Grid>

                    <Grid item={true} xs={6}>
                      <Typography variant="subtitle1">{t('Role')}</Typography>
                    </Grid>

                    {Object.keys(userRoles).map((org, index) => (
                      <Fragment key={org}>
                        <Grid item={true} xs={6}>
                          <Typography
                            className={`marapp-qa-workspace marapp-qa-workspace-${index}`}
                          >
                            {workspaces[org]}
                          </Typography>
                        </Grid>

                        <Grid item={true} xs={6}>
                          <Typography
                            lassName={`marapp-qa-workspace-role marapp-qa-workspace-role-${index}`}
                          >
                            {userRoles[org].join(', ')}
                          </Typography>
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                ) : (
                  <Typography color="textSecondary">{t('No workspaces available')}</Typography>
                )}
              </>
            </InlineEditCard>
          </Grid>

          <Grid item={true} xs={12}>
            <InlineEditCard
              render={() => (
                <>
                  <Typography color="textSecondary" variant="subtitle2" gutterBottom={true}>
                    {t('Account access')}
                  </Typography>

                  <Typography>
                    {t(
                      'Deleting your account will remove you from all public & private workspaces, and'
                    )}
                    <br />
                    {t('erase all of your settings')}.
                  </Typography>

                  <Box my={1}>
                    <FormControlLabel
                      classes={{
                        root: classes.deleteCheckboxContainer,
                        label: classes.deleteCheckboxLabel,
                      }}
                      control={
                        <Checkbox
                          checked={confirmDeleteAccount}
                          onChange={(e) => setConfirmDeleteAccount(e.target.checked)}
                          className="marapp-qa-confirm-delete"
                        />
                      }
                      label={
                        <Typography component="span" variant="body2">
                          {t(
                            'I understand this will permanently delete my account and that this action can'
                          )}
                          <br />
                          {t('not be undone')}.
                        </Typography>
                      }
                    />
                  </Box>
                </>
              )}
              hideEditButton={true}
              submitButtonVariant="error"
              submitButtonText={t('DELETE')}
              onCancel={() => {
                setConfirmDeleteAccount(false);
              }}
              onSubmit={deleteAccount}
              validForm={confirmDeleteAccount}
            >
              {({ setIsEditing }) => (
                <>
                  <Typography color="textSecondary" variant="subtitle2" gutterBottom={true}>
                    {t('Account access')}
                  </Typography>

                  <Button
                    type="button"
                    className="marapp-qa-deleteaccount"
                    onClick={() => setIsEditing(true)}
                    variant="outlined"
                    size="large"
                  >
                    {t('Delete your account')}
                  </Button>
                </>
              )}
            </InlineEditCard>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

const MapViewRedirect = (props: IMapViewRedirectProps) => {
  const { children, hasLeftOrg, resetStore } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    // TODO: remove this when we find a way to do a full reset (redux and auth0 context) and use redux-first-router link
    hasLeftOrg ? (
      <a href={`${PUBLIC_URL}earth`} className={classes.mapRedirect}>
        {children}
      </a>
    ) : (
      <Link
        className={classes.mapRedirect}
        onClick={resetStore}
        to={{
          type: 'EARTH',
        }}
      >
        {children}
      </Link>
    )
  );
};
